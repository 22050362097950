import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useInView } from 'react-intersection-observer';
import {
  useViewportScroll,
  useMotionValue,
  AnimatePresence,
} from 'framer-motion';

import { Chapter } from './Chapter';
import { PreloadChapterImages } from './PreloadChapterImages';
import { ScrollDownIndicator } from './SrollDownIndicator';

import chapterOne from '../../../main/assets/images/fpvAce/Chapter_first.webp';
import chapterTwo from '../../../main/assets/images/fpvAce/Chapter_second.webp';
import chapterThree from '../../../main/assets/images/fpvAce/Chapter_third.webp';

const CHAPTER_DATA = [
  {
    id: 1,
    image: chapterOne,
    title: 'Chapter 1',
    subtitle: 'drone sim mastery',
    description:
      'Master the art of flying with Orqa FPV.SkyDive, realistic drone simulator.',
    ctaUrl:
      'https://shop.orqafpv.com/products/fpv-ace-chapter-1?_pos=2&_sid=8aa2379e9&_ss=r',
  },
  {
    id: 2,
    image: chapterTwo,
    title: 'Chapter 2',
    subtitle: 'los take off',
    description:
      'Take off to new adventures, test your skills with a lightweight, resistant and easy-to-use drone to unleash your flying potential.',
    ctaUrl:
      'https://shop.orqafpv.com/products/orqa-fpv-ace-chapter-2?_pos=1&_sid=8aa2379e9&_ss=r',
  },
  {
    id: 3,
    image: chapterThree,
    title: 'Chapter 3',
    subtitle: 'fpv dive in',
    description:
      'Dive into the immersive world of FPV flying, discover a whole new perspective and experince the thrill of flying with both your feet safely on the ground.',
    ctaUrl:
      'https://shop.orqafpv.com/products/orqa-fpv-ace-chapter-3?_pos=3&_sid=8aa2379e9&_ss=r',
  },
];

export const Chapters = () => {
  const sectionRef = useRef<HTMLElement | null>(null);
  const { scrollY } = useViewportScroll();
  const scrollProgress = useMotionValue(0);
  const [isSectionInView, setIsSectionInView] = useState(false);
  const [activeChapter, setActiveChapter] = useState<number | null>(null);
  const [isScrollDirectionDown, setIsScrollDirectionDown] = useState<
    boolean | null
  >(null);
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });
  const [manualProgress, setManualProgress] = useState<number>(0);
  const [stopDisplayingIndicator, setStopDisplayingIndicator] = useState<
    boolean
  >(false);

  const responsiveThreshold = useMemo(
    () => (windowSize.width < 992 ? 0.3 : 0.32),
    [windowSize.width],
  );

  const { ref: inViewRef, inView } = useInView({
    threshold: responsiveThreshold,
    triggerOnce: false,
  });

  const setRefs = useCallback(
    (node: HTMLElement | null) => {
      sectionRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsSectionInView(inView);
    if (!inView) {
      scrollProgress.set(0);
      setActiveChapter(null);
    }
  }, [inView, scrollProgress]);

  useEffect(() => {
    const updateScrollProgress = () => {
      if (sectionRef.current && isSectionInView) {
        const sectionTop = sectionRef.current.offsetTop;
        const sectionHeight = sectionRef.current.offsetHeight;
        const currentScroll = scrollY.get();
        const progress = Math.max(
          0,
          Math.min(1, (currentScroll - sectionTop) / sectionHeight),
        );
        scrollProgress.set(progress);
        setManualProgress(progress);

        // Set active chapter based on scroll progress
        if (windowSize.width > 991) {
          if (progress >= 0 && progress < 0.2) {
            setActiveChapter(1);
            setStopDisplayingIndicator(true);
          } else if (progress >= 0.2 && progress < 0.44) {
            setActiveChapter(2);
          } else if (progress >= 0.44 && progress < 0.68) {
            setActiveChapter(3);
          } else if (progress >= 0.68) {
            setActiveChapter(null);
          }
        } else {
          if (progress >= 0 && progress < 0.22) {
            setActiveChapter(1);
            setStopDisplayingIndicator(true);
          } else if (progress >= 0.22 && progress < 0.46) {
            setActiveChapter(2);
          } else if (progress >= 0.46 && progress < 0.76) {
            setActiveChapter(3);
          } else if (progress >= 0.76) {
            setActiveChapter(null);
          }
        }
      } else {
        setIsScrollDirectionDown(null);
      }
    };

    const unsubscribe = scrollY.onChange(updateScrollProgress);
    return () => unsubscribe();
  }, [scrollY, scrollProgress, isSectionInView, windowSize.width]);

  useEffect(() => {
    if (sectionRef.current && isSectionInView) {
      if (isScrollDirectionDown === null && activeChapter !== null) {
        setIsScrollDirectionDown(
          scrollY.get() < 3000 && activeChapter === 1 ? true : false,
        );
      }
    }
  }, [activeChapter, isScrollDirectionDown, isSectionInView, scrollY]);

  return (
    <section
      ref={setRefs}
      style={{
        position: 'relative',
        height: '2000px',
        overflow: 'hidden',
      }}
    >
      {!stopDisplayingIndicator && (
        <ScrollDownIndicator screenSize={windowSize.width} />
      )}
      <PreloadChapterImages />
      {CHAPTER_DATA.map(chapter => (
        <AnimatePresence key={`chapter-${chapter.id}`}>
          {activeChapter === chapter.id && (
            <Chapter
              image={chapter.image}
              title={chapter.title}
              subtitle={chapter.subtitle}
              description={chapter.description}
              ctaUrl={chapter.ctaUrl}
              isActive={true}
              index={chapter.id}
              scrollProgress={scrollProgress}
              manualProgress={manualProgress}
              scrollDirection={isScrollDirectionDown}
            />
          )}
        </AnimatePresence>
      ))}
    </section>
  );
};
