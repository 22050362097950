import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { configureStore } from 'modules/redux-store';
import { client } from 'modules/prismic';
import { BlogContextProvider } from 'modules';
import { Footer, Layout, ScrollToTop } from 'main/components';

import { routes } from '../constants';

export const Routing = () => {
  const store = configureStore();
  const isDefenseRoute =
    window.location.pathname === '/asda' ||
    window.location.pathname === '/asda/contact';
  if (!isDefenseRoute) {
    (window as any).FreshworksWidget('show');
  } else {
    (window as any).FreshworksWidget('hide');
  }

  return (
    <Provider store={store}>
      <BlogContextProvider>
        <ApolloProvider client={client}>
          <HelmetProvider>
            <BrowserRouter>
              <ScrollToTop>
                {!isDefenseRoute ? (
                  <Layout>
                    <Route
                      render={({ location }) => (
                        <TransitionGroup>
                          <CSSTransition
                            key={location.key}
                            timeout={2000}
                            classNames="page-container"
                            unmountOnExit
                          >
                            <Switch location={location}>
                              {routes.map(({ path, Component, exact }) => (
                                <Route key={path} exact={exact} path={path}>
                                  <div className="page-container">
                                    <div
                                      id="glass__effect"
                                      className="glass__effect"
                                    />
                                    <Component />
                                    <Footer />
                                  </div>
                                </Route>
                              ))}
                            </Switch>
                          </CSSTransition>
                        </TransitionGroup>
                      )}
                    />
                  </Layout>
                ) : (
                  <Route
                    render={({ location }) => (
                      <TransitionGroup>
                        <CSSTransition
                          key={location.key}
                          timeout={2000}
                          classNames="page-container"
                          unmountOnExit
                        >
                          <Switch location={location}>
                            {routes.map(({ path, Component, exact }) => (
                              <Route key={path} exact={exact} path={path}>
                                <div className="page-container">
                                  <div
                                    id="glass__effect"
                                    className="glass__effect"
                                  />
                                  <Component />
                                  {/* <Footer /> */}
                                </div>
                              </Route>
                            ))}
                          </Switch>
                        </CSSTransition>
                      </TransitionGroup>
                    )}
                  />
                )}
              </ScrollToTop>
            </BrowserRouter>
          </HelmetProvider>
        </ApolloProvider>
      </BlogContextProvider>
    </Provider>
  );
};

export default withRouter(Routing);
