import React from 'react';

import BundleImg from 'main/assets/images/fpvAce/Bundle.webp';
import { LazyImage } from 'main/components';

export const Hero = () => {
  return (
    <div className="fpv-ace-hero-container">
      <LazyImage
        src={require('main/assets/images/fpvAce/Red_Effect_desktop.webp')}
        alt="Pilot spotlight"
        className="fpv-ace-hero-effect"
      />
      <div className="fpv-ace-hero-content">
        <div className="fpv-ace-hero-text">
          <h2 className="fpv-ace-hero-product">
            FPV.ACE
            <br />
          </h2>
          <h1 className="fpv-ace-hero-title">fly-guide</h1>
        </div>
        <p className="fpv-ace-hero-description">
          Learning to fly a drone can be challenging, but FPV.Ace makes it
          seamless, fun, and accessible to everyone. With our all-in-one system,
          mastering FPV (first-person view) flying has never been easier.
        </p>

        <div className="dreamX__discover--button">
          <a
            href="https://shop.orqafpv.com/search?type=product&options%5Bprefix%5D=last&options%5Bunavailable_products%5D=last&q=chapters"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--primary btn--med"
            onMouseDown={event => event.preventDefault()}
          >
            Buy Now
          </a>
        </div>
      </div>
      <img
        src={BundleImg}
        alt="Pilot spotlight"
        className="fpv-ace-hero-img"
        loading="lazy"
      />
    </div>
  );
};
