import { useEffect } from 'react';

import chapterOne from '../../../main/assets/images/fpvAce/Chapter_first.webp';
import chapterTwo from '../../../main/assets/images/fpvAce/Chapter_second.webp';
import chapterThree from '../../../main/assets/images/fpvAce/Chapter_third.webp';

export const PreloadChapterImages = () => {
  useEffect(() => {
    (window as Window & {
      preloadedImages?: HTMLImageElement[];
    }).preloadedImages = [];

    const images = [chapterOne, chapterTwo, chapterThree];

    images.forEach(src => {
      const img = new Image();
      img.src = src;
      ((window as unknown) as Window & {
        preloadedImages: HTMLImageElement[];
      }).preloadedImages.push(img);
    });

    return () => {
      (window as Window & {
        preloadedImages?: HTMLImageElement[];
      }).preloadedImages = undefined;
    };
  }, []);

  return null;
};
