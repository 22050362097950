import React from 'react';

import { SEO } from 'main/components';
import { BoxesContent, Chapters, Hero, StartJourney } from 'modules/fpvAce';

export const FPVAce = () => {
  return (
    <>
      <SEO seoTitle="ORQA - FPV.Ace" seoDescription="" />
      <section className="fpv-ace-section">
        <Hero />
        <BoxesContent />
        <Chapters />
        <StartJourney />
      </section>
    </>
  );
};
