import * as React from 'react';
import { Link } from 'react-router-dom';

import { LazyImage } from './LazyImage';

import orqaLogo from '../assets/images/orqa-logo.png';

export const Footer: React.FC = () => (
  <footer className="footer" role="contentinfo">
    <div className="wrapper">
      <div className="footer__container">
        <span className="footer__line" />
        <section className="footer__branding">
          <LazyImage src={orqaLogo} alt="ORQA logo" height={50} />
          <p className="footer__description">
            High End equipment for FPV drones.
          </p>
          <img
            src={require('main/assets/images/iso-9001-certificate.webp')}
            alt="ISO 9001 certificate"
            className="footer__certificate"
          />
        </section>

        <section className="footer__section footer__reseller">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Resellers</h2>
          </div>
          <div>
            <Link to="/resellers" className="t-zeta">
              List of resellers
            </Link>
          </div>
        </section>
        <section className="footer__section footer__resources">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Resources</h2>
          </div>
          <div>
            <div>
              <Link to="/downloads" className="t-zeta">
                Downloads
              </Link>
            </div>
            <div>
              <Link to="/manual" className="t-zeta">
                Manual
              </Link>
            </div>
            <div>
              <Link to="/news/" className="t-zeta">
                News
              </Link>
            </div>
          </div>
        </section>
        <section className="footer__section footer__support">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Support</h2>
          </div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="t-zeta"
              onMouseDown={event => event.preventDefault()}
              onClick={() => (window as any).FreshworksWidget('open')}
              style={{ cursor: 'pointer' }}
            >
              Submit ticket
            </a>
          </div>
          <div>
            <Link to="/privacy-policy" className="t-zeta">
              Privacy Policy
            </Link>
          </div>
        </section>

        <section className="footer__section footer__info">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">More</h2>
          </div>
          <div>
            <Link to="/jobs" className="t-zeta">
              Jobs
            </Link>
          </div>
        </section>
        <section className="footer__section">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg t-nowrap">
              EU Projects
            </h2>
          </div>
          <div>
            <Link to="/eu-projects/esif" className="t-zeta">
              <span className="t-upper">Esif</span> financial instruments
            </Link>
            <Link to="/eu-projects/eu-Internacionalizacija" className="t-zeta">
              Internationalization of ORQA solutions
            </Link>
            <Link to="/eu-projects/eu-razvojna-ispitivanja" className="t-zeta">
              Development testing on Orqa Microdron and Orqa Drone Soccer drones
            </Link>
          </div>
        </section>
      </div>
    </div>
    <div className="wrapper footer__zicer">
      <LazyImage
        className="footer__zicer-logo"
        src={require('main/assets/images/zicer-logo.png')}
        alt="ZICER logo"
      />
      <p className="footer__zicer__text">
        Član Zagrebačkog inovacijskog centra d.o.o.
      </p>
    </div>
    <div className="wrapper">
      <section className="footer__copy">
        <p className="t-zeta">
          Copyright © {new Date().getFullYear()} Orqa Ltd. All rights reserved.
        </p>
      </section>
    </div>
  </footer>
);
