import React from 'react';

export const StartJourney = () => {
  return (
    <div className="fpv-ace-cta">
      <p className="fpv-ace-cta-text">
        Ready to elevate your drone game? Start your journey with FPV.Ace today!
      </p>

      <div className="dreamX__discover--button" style={{ textAlign: 'center' }}>
        <a
          href="https://shop.orqafpv.com/search?type=product&options%5Bprefix%5D=last&options%5Bunavailable_products%5D=last&q=chapters"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--primary btn--med"
          onMouseDown={event => event.preventDefault()}
        >
          Buy Now
        </a>
      </div>
    </div>
  );
};
