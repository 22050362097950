import { useEffect, useRef, useState } from 'react';

export const useImagePreloader = (src: string) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const img = new Image();
    imgRef.current = img;

    img.onload = () => setLoaded(true);
    img.src = src;

    return () => {
      if (imgRef.current) {
        imgRef.current.onload = null;
      }
    };
  }, [src]);

  return loaded;
};
