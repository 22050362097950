import React, { useEffect, useState } from 'react';

import bgImage from '../../../main/assets/images/fpvAce/Noise Effect.webp';
import containerBgImageDesktop from '../../../main/assets/images/fpvAce/Boxes_content_bg.webp';
import containerBgImageMobile from '../../../main/assets/images/fpvAce/Boxes_content_vertical_bg.webp';
import boxFirstChapter from '../../../main/assets/images/fpvAce/Box_first_chapter.webp';
import boxSecondChapter from '../../../main/assets/images/fpvAce/Box_second_chapter.webp';
import boxThirdChapter from '../../../main/assets/images/fpvAce/Box_third_chapter.webp';

const boxData = [
  {
    bgImage: bgImage,
    mainImage: boxFirstChapter,
    text: 'Reliable FPV.CTRL controller for accurate navigation',
  },
  {
    bgImage: bgImage,
    mainImage: boxSecondChapter,
    text: 'Whoop and Ghost connector - best duo in the game',
  },
  {
    bgImage: bgImage,
    mainImage: boxThirdChapter,
    text: 'Flawless latency of Race goggles with real-time feedback',
  },
];

export const BoxesContent = () => {
  const [containerBg, setContainerBg] = useState(containerBgImageDesktop);

  useEffect(() => {
    const updateBackground = () => {
      if (window.innerWidth <= 991) {
        setContainerBg(containerBgImageMobile);
      } else {
        setContainerBg(containerBgImageDesktop);
      }
    };

    updateBackground(); // Call it initially
    window.addEventListener('resize', updateBackground);

    return () => window.removeEventListener('resize', updateBackground);
  }, []);

  return (
    <div className="fpv-ace-boxes-container">
      <img src={containerBg} className="fpv-ace-boxes-bg" />
      <div className="fpv-ace-boxes-header">
        <h2 className="fpv-ace-boxes-title">What’s in the boxes?</h2>
        <h3 className="fpv-ace-boxes-subtitle">
          FPV.Ace contains every tool you need to fly, including instructions to
          help you get there smoothly.
        </h3>
      </div>
      <div className="fpv-ace-boxes">
        {boxData.map((box, index) => (
          <div key={index} className="fpv-ace-box">
            <div
              className="fpv-ace-bg-image"
              style={{ backgroundImage: `url(${box.bgImage})` }}
            />
            <img
              src={box.mainImage}
              alt="Pilot spotlight"
              className="fpv-ace-main-image"
            />
            <p className="fpv-ace-text">{box.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
