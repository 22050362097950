import React, { FC, useCallback, useMemo } from 'react';
import {
  AnimatePresence,
  motion,
  MotionValue,
  useTransform,
} from 'framer-motion';

import { useImagePreloader } from '../hooks';

interface ChapterProps {
  image: string;
  title: string;
  subtitle: string;
  description: string;
  ctaUrl: string;
  isActive: boolean;
  index: number;
  scrollProgress: MotionValue<number>;
  manualProgress: number;
  scrollDirection: boolean | null;
}

/* linear mapping from one range of values to another */
// inputRange - outputRange -> mapping between two numerical intervals
// progress -> represents a point within the input range that we want to map to the out range
const getInterpolatedValue = (
  progress: number,
  inputRange: [number, number],
  outputRange: [number, number],
) => {
  const [inputStart, inputEnd] = inputRange;
  const [outputStart, outputEnd] = outputRange;

  // ensures that the input progress stays within the bounds of the inputRange
  const clampedProgress = Math.min(Math.max(progress, inputStart), inputEnd);

  // normalized position of the clampedProgress within the inputRange
  const ratio = (clampedProgress - inputStart) / (inputEnd - inputStart);

  // uses ratio to find the corresponding value within the outputRange
  return outputStart + (outputEnd - outputStart) * ratio;
};

export const Chapter: FC<ChapterProps> = ({
  image,
  title,
  subtitle,
  description,
  ctaUrl,
  isActive,
  index,
  scrollProgress,
  manualProgress,
  scrollDirection,
}) => {
  const isLoaded = useImagePreloader(image);

  const getTriggerPoint = useCallback((index: number) => {
    switch (index) {
      case 1:
        return 0.16;
      case 2:
        return 0.4;
      case 3:
        return 0.64;
      default:
        return 1;
    }
  }, []);

  const getTriggerPointUp = useCallback((index: number) => {
    switch (index) {
      case 1:
        return 0.05;
      case 2:
        return 0.24;
      case 3:
        return 0.48;
      default:
        return 1;
    }
  }, []);

  const textAnimationVariants = useMemo(
    () => ({
      enter: (direction: boolean | null) => {
        if (direction === null) {
          return {
            y: scrollProgress.get() < 0.5 ? 64 : -64,
            opacity: 0,
          };
        }
        return {
          y: direction ? 64 : -64,
          opacity: 0,
        };
      },
      center: {
        y: 0,
        opacity: 1,
        transition: { duration: 1 },
      },
      exit: (direction: boolean | null) => {
        if (direction === null) {
          return {
            y: scrollProgress.get() < 0.5 ? -64 : 64,
            opacity: 0,
            transition: { duration: 1, ease: 'easeInOut' },
          };
        }
        return {
          y: direction ? -64 : 64,
          opacity: 0,
          transition: { duration: 1, ease: 'easeInOut' },
        };
      },
    }),
    [scrollProgress],
  );

  const triggerPoint = useMemo(
    () => (scrollDirection ? getTriggerPoint(index) : getTriggerPointUp(index)),
    [scrollDirection, getTriggerPoint, index, getTriggerPointUp],
  );

  const manualScale = getInterpolatedValue(
    manualProgress,
    [triggerPoint, triggerPoint + 0.1],
    [1, 0.8],
  );

  const manualY = getInterpolatedValue(
    manualProgress,
    [triggerPoint, triggerPoint + 0.1],
    [0, 50],
  );

  const autoScale = useTransform(
    scrollProgress,
    [triggerPoint - 0.1, triggerPoint],
    [0.8, 1],
  );

  const autoY = useTransform(
    scrollProgress,
    [triggerPoint - 0.1, triggerPoint],
    [-50, 0],
  );

  const scale = scrollDirection ? manualScale : autoScale;
  const y = scrollDirection ? manualY : autoY;

  return (
    <motion.div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        translateX: '-50%',
        translateY: '-50%',
        textAlign: 'center',
        maxWidth: '90vw',
        width: '100%',
        pointerEvents: isActive ? 'auto' : 'none',
        scale:
          scrollDirection === false && scrollProgress.get() > 0.65 ? 1 : scale,
        y: scrollDirection === false && scrollProgress.get() > 0.65 ? 0 : y,
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: isActive ? 1 : 0,
        transition: { duration: 1 },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration:
            (index === 1 && scrollProgress.get() < 0.1) ||
            (index === 3 && scrollProgress.get() > 0.6)
              ? 0.15
              : 0.3,
        },
      }}
    >
      <div className="fpv-container">
        <div className="fpv-img-container">
          <div className="fpv-red-effect" style={{ filter: 'blur(54px)' }} />
          {isLoaded && <img src={image} className="fpv-img" />}
        </div>
        <div className="fpv-text-container">
          <div className="fpv-text-sub-container">
            <AnimatePresence custom={scrollDirection}>
              {isActive && (
                <>
                  <motion.div
                    key={`subtitle-${index}`}
                    custom={scrollDirection}
                    variants={textAnimationVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className="fpv-subtitle"
                  >
                    {subtitle}
                  </motion.div>
                  <motion.div
                    key={`title-${index}`}
                    custom={scrollDirection}
                    variants={textAnimationVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className="fpv-title"
                  >
                    {title}
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </div>
          <AnimatePresence custom={scrollDirection}>
            {isActive && (
              <motion.div
                key={`description-${index}`}
                custom={scrollDirection}
                variants={textAnimationVariants}
                initial="enter"
                animate="center"
                exit="exit"
                className="fpv-description"
              >
                {description}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence custom={scrollDirection}>
            <motion.div
              key={`ctaUrl-${index}`}
              custom={scrollDirection}
              variants={textAnimationVariants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <div className="dreamX__discover--button">
                <a
                  href={ctaUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--primary btn--med"
                  onMouseDown={event => event.preventDefault()}
                >
                  Buy Now
                </a>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};
